import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Items from "./Items";
export default function ChannelSwitcher() {
  return (
    <div style={{}}>
      {["Main", "Second", "Player1", "Player2"].map((value) => (
        <div
          key={value}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 70,
            fontSize: 10,

            borderBottom: "1px solid #ccc",
            color: "white",
          }}
        >
          <b>{value}</b>
          <Items key={value} type={value} />
        </div>
      ))}
    </div>
  );
}
