import { createSlice } from "@reduxjs/toolkit";

export const watcherSlicer = createSlice({
  name: "watcher",
  initialState: {
    mainScreen: "",
    secondScreen: "",
    player1: "",
    player2: "",
  },
  reducers: {
    storeMainScreen: (state, action) => {
      state.mainScreen = action.payload;
    },
    storeSecondScreen: (state, action) => {
      state.secondScreen = action.payload;
    },
    storePlayer1: (state, action) => {
      state.player1 = action.payload;
    },
    storePlayer2: (state, action) => {
      state.player2 = action.payload;
    },
    storePlayer3: (state, action) => {
      state.player3 = action.payload;
    },
  },
});

export const {
  storeMainScreen,
  storeSecondScreen,
  storePlayer1,
  storePlayer2,
  storePlayer3,
} = watcherSlicer.actions;

export default watcherSlicer.reducer;
