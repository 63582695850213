import React, { useState, useEffect } from "react";
import Player from "./component/Player";
import Chat from "./component/Chat";
import Auth from "./component/Twitch/Auth";

import { TwitchPlayer, TwitchChat } from "react-twitch-embed";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";

import ChannelSwitcher from "./component/ChannelSwitrher/ChannelSwither";
interface Follower {
  from_id: string;
  from_name: string;
  to_id: string;
  to_name: string;
  followed_at: string;
}
function App(): JSX.Element {
  const mainStream = useSelector(
    (state: any) => state.watcher.mainScreen || "skiben"
  );
  const secondStream = useSelector(
    (state: any) => state.watcher.secondScreen || "detoo"
  );
  const smallPlayer_1 = useSelector(
    (state: any) => state.watcher.player1 || "Knut"
  );
  const smallPlayer_2 = useSelector(
    (state: any) => state.watcher.player2 || "xQc"
  );

  const handleContextMenu: React.MouseEventHandler = (e) => {
    e.preventDefault();
    // Show your custom context menu here
    // For simplicity, let's just log the event coordinates for now
    console.log("Right-clicked at:", { x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    window.addEventListener("contextmenu", handleContextMenu as any);
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu as any);
    };
  }, []);

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{
        backgroundColor: "#18181b",
        display: "grid",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100vh",
        width: "100vw",
        gridTemplateRows: "1fr 1fr .1fr .8fr .5fr",
        gridTemplateColumns: ".6fr  .2fr .03fr .3fr ",
      }}
    >
      <ChannelSwitcher />
      <Auth />
      <span
        style={{
          gridRowStart: 1,
          gridRowEnd: 3,
        }}
      >
        {/*   <iframe
          src={`https://player.twitch.tv/?channel=${mainStream}&parent=localhost`}
          height="100%"
          width="100%"
          frameBorder="0"
          scrolling="no"
        ></iframe>*/}
        <TwitchPlayer
          id={mainStream + "_123112"}
          height="100%"
          width="100%"
          muted={false}
          channel={mainStream}
        />
      </span>
      <div style={{ gridColumnStart: 4, gridRowStart: 1, gridRowEnd: 3 }}></div>
      <span style={{ gridColumnStart: 2, gridRowStart: 1, gridRowEnd: 6 }}>
        <TwitchChat height="100%" width="100%" channel={mainStream} />
      </span>
      <span style={{ gridColumnStart: 4, gridRowStart: 1, gridRowEnd: 3 }}>
        <TwitchChat height="100%" width="100%" channel={secondStream} />
      </span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridRowStart: 3,
          gridRowEnd: 5,
        }}
      >
        {smallPlayer_1 && (
          <TwitchPlayer
            id={smallPlayer_1 + "_123112"}
            height="100%"
            width="100%"
            channel={smallPlayer_1}
          />
        )}
        {smallPlayer_2 && (
          <TwitchPlayer
            id={smallPlayer_2 + "_123112"}
            height="100%"
            width="100%"
            channel={smallPlayer_2}
          />
        )}
      </div>

      <span
        style={{
          gridColumnStart: 3,
          gridColumnEnd: 5,
          gridRowStart: 3,
          gridRowEnd: 6,
        }}
      >
        <TwitchPlayer
          height="100%"
          width="100%"
          id={secondStream + "_123112"}
          channel={secondStream}
        />
      </span>
    </div>
  );
}

export default App;
