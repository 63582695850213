import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  storeFollowers,
  storeProfile,
  storeRandomChannels,
} from "../../slices/userSlice";
import {
  storeMainScreen,
  storeSecondScreen,
  storePlayer1,
  storePlayer2,
} from "../../slices/watcherSlice";

interface ChannelSwitcherItem {
  type: string;
}

export default function Items({ type }: ChannelSwitcherItem) {
  const [Channel, setChannel] = useState("");
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.user.profile) || {};
  const randomChannels =
    useSelector((state: any) => state.user.randomChannels) || [];
  const followers = useSelector((state: any) => state.user.followers) || [];
  const player1 = useSelector((state: any) => state.watcher.player1);
  const main = useSelector((state: any) => state.watcher.mainScreen);
  const second = useSelector((state: any) => state.watcher.secondScreen);
  const player2 = useSelector((state: any) => state.watcher.player2);

  const [mainSafe, setmainSafe] = useState("");
  const [secondSafe, setsecondSafe] = useState("");
  const [player1Safe, setplayer1Safe] = useState("");
  const [player2Safe, setplayer2Safe] = useState("");

  useEffect(() => {
    switch (type) {
      case "Main":
        setChannel(mainSafe);
        break;
      case "Second":
        setChannel(secondSafe);
        break;
      case "Player1":
        setChannel(player1Safe);
        break;
      case "Player2":
        setChannel(player2Safe);
        break;
      default:
        break;
    }
  }, [mainSafe, secondSafe, player1Safe, player2Safe]);

  useEffect(() => {
    switch (type) {
      case "Main":
        setmainSafe(main);
        break;
      case "Second":
        setsecondSafe(second);
        break;
      case "Player1":
        setplayer1Safe(player1);
        break;
      case "Player2":
        setplayer2Safe(player2);
        break;
      default:
        break;
    }
  }, [type, main, second, player1, player2]);

  const handleChange = (event: SelectChangeEvent) => {
    const newChannel = event.target.value;
    const oldChannel = Channel;
    let swapWith = "";
    console.log(swapWith);
    // Determine if the new channel is already being viewed and where
    if (newChannel === main) swapWith = "Main";
    else if (newChannel === second) swapWith = "Second";
    else if (newChannel === player1) swapWith = "Player1";
    else if (newChannel === player2) swapWith = "Player2";

    switch (type) {
      case "Main":
        dispatch(storeMainScreen(newChannel));
        break;
      case "Second":
        dispatch(storeSecondScreen(newChannel));
        break;
      case "Player1":
        dispatch(storePlayer1(newChannel));
        break;
      case "Player2":
        dispatch(storePlayer2(newChannel));
        break;
      default:
        break;
    }
    // Swap logic using the old channel value from the ref
    if (swapWith) {
      switch (swapWith) {
        case "Main":
          dispatch(storeMainScreen(oldChannel));
          break;
        case "Second":
          dispatch(storeSecondScreen(oldChannel));
          break;
        case "Player1":
          dispatch(storePlayer1(oldChannel));
          break;
        case "Player2":
          dispatch(storePlayer2(oldChannel));
          break;
        default:
          break;
      }
    }
  };

  return (
    <div key={type}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        {" "}
        <Select
          value={Channel}
          style={{ color: "white" }}
          onChange={handleChange}
        >
          <MenuItem key={"none"} value={"**random**"}>
            ~~~~~~ Followers ~~~~~~
          </MenuItem>
          {followers.length > 0 &&
            followers.map((follower: any) => (
              <MenuItem key={follower.user_name} value={follower.user_name}>
                {follower.user_name}
              </MenuItem>
            ))}
          <MenuItem key={"none"} value={"**random**"}>
            ~~~~~~ Random Channels ~~~~~~
          </MenuItem>
          {randomChannels.length > 0 &&
            randomChannels.map((follower: any) => (
              <MenuItem key={follower.user_name} value={follower.user_name}>
                {follower.user_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
