import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  storeFollowers,
  storeProfile,
  storeRandomChannels,
  storeTwitchToken,
} from "../../slices/userSlice";
import { enqueueSnackbar } from "notistack";
import { Button } from "@mui/material";
import { accessSync } from "fs";
interface Follower {
  user_name: string;
}

interface Stream {
  id: string;
  user_id: string;
  user_name: string;
  game_id: string;
  game_name: string;
}

export default function Auth(): JSX.Element {
  const [userId, setUserId] = useState<string>("");
  const [randomStreams, setRandomStreams] = useState<Stream[]>([]);
  const followersState = useSelector((state: any) => state.user.followers);
  const accessToken = useSelector((state: any) => state.user.twitchToken);
  const dispatch = useDispatch();

  const handleTwitchAuth = async (): Promise<void> => {
    const clientId: string = "5rimcktn947766uz6xb3r2uiqoegrv";
    const redirectUri: string = "https://hyperwatch.6c6.no/";
    //const redirectUri: string = "http://localhost:3000/";
    window.location.assign(
      `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=user:read:follows`
    );
  };

  const getFollowers = async (
    accessToken: string,
    userId: string
  ): Promise<void> => {
    try {
      if (typeof accessToken !== "string" || accessToken.length <= 4) {
        return; // Exit if not
      }
      const response: Response = await fetch(
        `https://api.twitch.tv/helix/streams/followed?user_id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Client-Id": "5rimcktn947766uz6xb3r2uiqoegrv",
          },
        }
      );

      if (response.ok) {
        const data: { data: Follower[] } = await response.json();

        // Check if data contains followers
        if (data.data && data.data.length > 0) {
          const newFollowers = data.data.filter(
            (follower) =>
              !followersState.some(
                (existingFollower: Follower) =>
                  existingFollower.user_name === follower.user_name
              )
          );

          // Store new followers in an array
          const newFollowersArray = newFollowers.map(
            (follower) => follower.user_name
          );
          newFollowers.map((value) => {
            enqueueSnackbar(value.user_name + " er nå live!");
          });
          // Dispatch action to store new followers
          dispatch(storeFollowers(data.data));

          // Optionally, you can do something with the new followers array
        } else {
          console.log("No followers fetched.");
        }
      } else {
        console.error("Failed to fetch followers:", response.statusText);
      }
    } catch (error: any) {
      console.error("Error fetching followers:", error.message);
    }
  };

  useEffect(() => {
    if (typeof accessToken === "string" && accessToken.length > 4 && userId) {
      const followersInterval = setInterval(() => {
        getFollowers(accessToken, userId);
      }, 15000);

      const streamsInterval = setInterval(() => {
        getRandomStreams(accessToken);
      }, 30000);

      return () => {
        clearInterval(followersInterval);
        clearInterval(streamsInterval);
      };
    }
  }, [accessToken, followersState, userId]);

  const getRandomStreams = async (accessToken: string): Promise<void> => {
    try {
      if (typeof accessToken !== "string" || accessToken.length <= 4) {
        return; // Exit if not
      }
      const response: Response = await fetch(
        "https://api.twitch.tv/helix/streams",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Client-Id": "5rimcktn947766uz6xb3r2uiqoegrv",
          },
        }
      );

      if (response.ok) {
        const data: { data: Stream[] } = await response.json();
        setRandomStreams(data.data);
        dispatch(storeRandomChannels(data.data));
      } else {
        console.error("Failed to fetch random streams:", response.statusText);
      }
    } catch (error: any) {
      console.error("Error fetching random streams:", error.message);
    }
  };

  useEffect(() => {
    const handleAccessToken = (): void => {
      const hash: string = window.location.hash.substring(1);
      const params: URLSearchParams = new URLSearchParams(hash);
      const accessToken: string | null = params.get("access_token");
      if (accessToken) {
        dispatch(storeTwitchToken(accessToken));
        fetchUserInfo(accessToken);
      }
    };

    const fetchUserInfo = async (accessToken: string): Promise<void> => {
      try {
        if (typeof accessToken !== "string" || accessToken.length <= 4) {
          return; // Exit if not
        }
        const response: Response = await fetch(
          "https://api.twitch.tv/helix/users",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Client-Id": "5rimcktn947766uz6xb3r2uiqoegrv",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.data && data.data.length > 0) {
            const userId = data.data[0].id;
            setUserId(userId);
            dispatch(storeProfile(data.data));

            // Fetch and store followers
            getFollowers(accessToken, userId);

            // Fetch and store random streams
            getRandomStreams(accessToken);
          }
        } else {
          console.error("Failed to fetch user info:", response.statusText);
        }
      } catch (error: any) {
        console.error("Error fetching user info:", error.message);
      }
    };

    handleAccessToken();
  }, [accessToken]); // Keep the dependency array as is

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        style={{
          backgroundColor: "#8c00c0",
          fontSize: ".6em",
          margin: "auto",
        }}
        onClick={handleTwitchAuth}
      >
        Sign in with Twitch
      </Button>
    </div>
  );
}
